import React from "react";
import {CompanyWrapper, Container, Credits, CreditsText, FooterWrapper, Logo, LogoWrapper, MenuList, MenuListItem, StyledAnchorLink,} from "./styles";
import {SectionWrapper} from "shared/Styles/Wrappers";
import {useTheme} from "styled-components";
import LogoIv from "assets/img/svg/intervi.svg";
import LogoIcon from "assets/img/logo-white.png";
import data from "data/Navbar";
import uuid from "react-uuid";

const Footer = () => {
  const theme = useTheme();

  return (
    <Container>
      <SectionWrapper>
        <FooterWrapper>
          <LogoWrapper to="hero" title="">
            <Logo src={LogoIcon} alt=""/>
          </LogoWrapper>
          {/*<MenuList>*/}
          {/*  {*/}
          {/*    data.map(link => (*/}
          {/*      <MenuListItem key={uuid()}>*/}
          {/*        <StyledAnchorLink to={link.slug} smooth hashSpy spy={true}>*/}
          {/*          {link.text}*/}
          {/*        </StyledAnchorLink>*/}
          {/*      </MenuListItem>*/}
          {/*    ))*/}
          {/*  }*/}
          {/*</MenuList>*/}
          <Credits>
            <CreditsText
              weight={theme.weights.less_bold}
              size="12px"
              color={theme.colors.white}
            >
              designed by:
            </CreditsText>
            <CompanyWrapper
              href="https://interactivevision.pl"
              target="_blank"
              rel="noopener"
              aria-label="interactivevision.pl"
            >
              <Logo src={LogoIv} alt="InteractiveVision"/>
            </CompanyWrapper>
          </Credits>
        </FooterWrapper>
      </SectionWrapper>
    </Container>
  );
};

export default Footer;
