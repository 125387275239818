import CookieConsent from "react-cookie-consent";
import React from "react";
import Icon from "assets/img/svg/cookie.svg";
import {Container, FlexContainer, Image, ImageWrapper, Text, TextBox, Title,} from "./styles.js";

const Cookies = () => (
  <Container>
    <CookieConsent
      disableStyles
      location="bottom"
      buttonText="Zamknij"
      cookieName="Cookies"
      expires={150}
      style={{alignItems: "center", width: "100%"}}
      containerClasses="cookies-wrapper"
      buttonWrapperClasses="btnWrap"
      flipButtons
    >
      <FlexContainer>
        <ImageWrapper>
          <Image src={Icon} alt=""/>
        </ImageWrapper>
        <TextBox>
          <Title>Polityka Cookies</Title>
          <Text>
            Jeżeli nie blokujesz w przeglądarce tych plików, to zgadzasz się
            na ich używanie oraz zapisywanie w pamięci urządzenia. W
            przeglądarce istnieje możliwość zarządzana plikami cookies.
            Korzystanie z serwisu bez blokowania plików cookies oznacza zgodę
            na ich wykorzystywanie.
          </Text>
        </TextBox>
      </FlexContainer>
    </CookieConsent>
  </Container>
);

export default Cookies;
