const theme = {
  colors: {
    primary: "#F6CD46",
    primary_hover: "#ddb83f",
    secondary: "#2E3942",
    section_bg: "#2E3942",
    black: "#242D34",
    black_hover: "#7b8185",
    white: "#fff",
  },
  fonts: {
    xl: "100px",
    l: "40px",
    m: "26px",
    s: "18px",
    xs: "16px",
    xxs: "14px",
    xxxs: "12px",
  },
  weights: {
    extra_thin: 100,
    normal: 300,
    light: 500,
    less_bold: 600,
    bold: 700,
  },
  breakpoints: {
    laptop_big: "1601px",
    laptop: "1480px",
    laptop_small: "1281px",
    tablet_big: "1100px",
    tablet: "867px",
    tablet_small: "769px",
    phone_big: "601px",
    phone: "431px",
    phone_small: "374px",
  },
};

export default theme;
