import React from "react";
import PropTypes from "prop-types";
import theme from "shared/Themes/theme";
import {ThemeProvider} from "styled-components";
import {ParallaxProvider} from "react-scroll-parallax";
import DefaultTransition from "components/transition/DefaultTransition";
import "shared/Styles/global.css";
import "shared/Styles/reset.css";
import {GlobalFont} from "shared/Styles/fonts";
import Footer from "components/footer/Footer";
import Cookies from "components/cookies/Cookies";
import Navbar from "../components/navbar/Navbar";

const MainLayout = ({children, pageContext, location}) => {
  if (pageContext.layout === "404") {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
  }

  return (
    <ParallaxProvider>
      <ThemeProvider theme={theme}>
        <DefaultTransition location={location}>
          <GlobalFont/>
          <Cookies/>
          <Navbar/>
          {children}
          <Footer/>
        </DefaultTransition>
      </ThemeProvider>
    </ParallaxProvider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
