import React, {useEffect, useState} from "react";
import LogoIcon from "assets/img/logo.png";
import {Burger, Container, Line, List, ListItem, Logo, LogoWrapper, StyledLink, Wrapper} from "./styles";
import {GlobalWrapper, SectionWrapper} from "../../shared/Styles/Wrappers";
import data from "data/Navbar";
import {useScrollPosition} from "../../shared/Hooks/useScrollPosition";
import uuid from "react-uuid";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleMenuToggle = () => {
    if (window.innerWidth < 769) {
      setIsOpen(!isOpen);
    }
  };

  useScrollPosition(({currPos}) => {
    if (currPos.y < -10) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  });

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("blocked");
    } else {
      document.body.classList.remove("blocked");
    }
  }, [isOpen])

  return (
    <Container className={scrolled ? "scrolled" : ""}>
      <GlobalWrapper>
        <SectionWrapper>
          <Wrapper>
            <LogoWrapper to="hero" title="Landing Discord">
              <Logo src={LogoIcon} alt="Landing Discord Logo"/>
            </LogoWrapper>
            <List className={isOpen ? "open" : ""}>
              {
                data.map(link => (
                  <ListItem key={uuid()}>
                    <StyledLink to={link.slug} smooth onClick={handleMenuToggle}>
                      {link.text}
                    </StyledLink>
                  </ListItem>
                ))
              }
            </List>
            <Burger onClick={handleMenuToggle} aria-label="burger" className={isOpen ? "open" : ""}>
              <Line/>
              <Line/>
              <Line/>
            </Burger>
          </Wrapper>
        </SectionWrapper>
      </GlobalWrapper>
    </Container>
  );
}

export default Navbar;
