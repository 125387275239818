import styled, {css} from "styled-components";
import {GlobalWrapper} from "shared/Styles/Wrappers";
import {StyledLink} from "../navbar/styles";
import {Link} from "react-scroll";

export const Container = styled(GlobalWrapper)`
    background-color: ${({theme}) => theme.colors.black};
    padding: 90px 0 180px 0;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        padding-bottom: 120px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        padding-bottom: 90px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        padding-top: 24px;
        padding-bottom: 72px;
    }
`;

export const FooterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({theme}) => theme.colors.white};
    position: relative;
    height: 50px;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        padding: 0 24px;
        height: auto;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        flex-direction: column;
        padding: 0;
    }
`;

export const LogoText = styled.span`
    font-size: 30px;
    line-height: 1.2;
    font-weight: ${({theme}) => theme.weights.bold};
    display: inline-block;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 20px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        margin: 0 auto 48px;
    }
`;

export const LogoWrapper = styled(Link)`
    display: inline-flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 62px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        position: relative;
        margin-bottom: 36px;
        transform: translateY(0%);
        top: 0;
    }
`;

export const MenuList = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        flex-direction: column;
        margin-bottom: 36px;
    }
`;

export const MenuListItem = styled.li`
    &:not(:last-child) {
        margin-right: 48px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        &:not(:last-child) {
            margin-right: 36px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop_small}) {
        &:not(:last-child) {
            margin-right: 32px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_big}) {
        &:not(:last-child) {
            margin-right: 26px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin-top: 20px;

        &:not(:last-child) {
            margin-right: 0;
        }
    }
`;

const defaultLink = css`
    font-size: 16px;
    transition: 0.2s color ease;
    font-weight: ${({theme}) => theme.weights.bold};

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 14px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 12px;
        &:last-child {
            margin-top: 24px;
            margin-left: 0;
        }
    }
`;

export const ExternalLink = styled.a`
    ${defaultLink};

    &:hover {
        color: ${({theme}) => theme.colors.light_gray};
    }
`;

export const Credits = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;


    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        justify-content: center;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        flex-direction: column;
        align-items: center;
        position: relative;
        transform: translateY(0%);
        top: 0;
    }
`;

export const StyledAnchorLink = styled(StyledLink)`
    color: ${({theme}) => theme.colors.white};
    cursor: pointer;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 12px;
    }
`;

export const CompanyWrapper = styled.a`
    width: 105px;
    height: 24px;
    display: flex;
    margin-left: 12px;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: 80px;
        height: 16px;
    }
`;

export const CreditsText = styled.span`
    font-size: 12px;
    color: ${({theme}) => theme.colors.white};
    font-weight: ${({theme}) => theme.weights.less_bold};

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: 10px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone_big}) {
        margin-bottom: 6px;
    }
`;

export const Logo = styled.img`
    width: 100%;
    height: 100%;
`;
