import styled, {css} from "styled-components";

const defaultStyles = css`
    font-size: 10px !important;
    font-weight: ${({theme}) => theme.weights.bold} !important;
    background-color: ${({theme}) => theme.colors.secondary} !important;
    transition: all 0.3s ease !important;
    color: ${({theme}) => theme.colors.primary} !important;
    border: 1px solid ${({theme}) => theme.colors.secondary} !important;
    outline: none !important;
    cursor: pointer !important;
    display: inline-block !important;
    line-height: 1 !important;
    position: relative !important;
    text-transform: uppercase !important;
    letter-spacing: 0.2px !important;
    width: 120px;
    height: 38px;

    &:hover {
        background-color: ${({theme}) => theme.colors.secondary_hover} !important;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        width: 100px;
        height: 30px;
        font-size: 10px;
    }
`;

export const Container = styled.div`
    margin: 0 auto;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
    padding: 0 60px;
    background: ${({theme}) => theme.colors.primary} !important;
    box-shadow: 0px -2px 8px -3px ${({theme}) => theme.colors.primary};

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        padding: 0;
    }

    .cookies-wrapper {
        position: relative !important;
        max-width: 1456px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap !important;
        padding: 24px 0;

        & > div:first-child {
            margin: 0 !important;
            flex: initial !important;
        }

        @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
            max-width: 1122px;
        }

        @media screen and (max-width: ${({theme}) =>
                theme.breakpoints.laptop_small}) {
            max-width: 970px;
        }

        @media screen and (max-width: ${({theme}) =>
                theme.breakpoints.tablet_big}) {
            max-width: 100%;
            padding: 24px;
        }

        @media screen and (max-width: ${({theme}) =>
                theme.breakpoints.tablet_small}) {
            padding: 0 24px 36px 24px;
            flex-direction: column;
            text-align: center;
        }
    }

    #rcc-confirm-button {
        ${defaultStyles}
    }
`;

export const FlexContainer = styled.div`
    display: flex;
    padding: 10px 0;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        padding: 30px 0;
        padding-bottom: 15px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        padding-top: 30px;
        padding-bottom: 0;
    }
`;

export const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    padding: 0 40px;
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        padding: 0 48px 0 24px;
    }
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        padding: 0;
    }
`;

export const Title = styled.span`
    font-family: "Rajdhani", sans-serif;
    font-weight: ${({theme}) => theme.weights.bold};
    color: ${({theme}) => theme.colors.black};
    padding-bottom: 16px;
    font-size: 20px;
    
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        font-size: 16px;
    }
`;

export const Text = styled.span`
    font-weight: ${({theme}) => theme.weights.light};
    font-size: 12px;
    line-height: 1.4;
    color: ${({theme}) => theme.colors.black};
    
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        font-size: 10px;
        margin-bottom: 24px;
    }
`;

export const ImageWrapper = styled.div`
    width: 67px;
    height: 67px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: 40px;
        height: 40px;
    }
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        display: none;
    }
`;

export const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
`;
