import {createGlobalStyle} from 'styled-components';
import "@fontsource/montserrat"
import "@fontsource/montserrat/100.css"
import "@fontsource/montserrat/300.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/rajdhani/300.css"
import "@fontsource/rajdhani/400.css"
import "@fontsource/rajdhani/600.css"
import "@fontsource/rajdhani/700.css"

const GlobalFont = createGlobalStyle`
    *,
    *::before,
    *::after {
        font-family: "Montserrat";
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: "Rajdhani";
    }
`

export {GlobalFont}
