const data = [
  {
    text: "O nas",
    slug: "about",
  },
  {
    text: "Nasze Produkty",
    slug: "products",
  },
  {
    text: "Wirtualny Asystent",
    slug: "wa",
  },
  {
    text: "Korzyści",
    slug: "benefits",
  },
  {
    text: "Wirtualny Asystent PRO",
    slug: "wa-pro",
  },
];

export default data;
