import styled from "styled-components";
import {Link} from "react-scroll";

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background: ${({theme}) => theme.colors.white};
    transition: 0.2s background-color ease;

    &.scrolled {
        box-shadow: 0px 0px 20px -5px rgba(255, 206, 105, 0.9);
    }
`;

export const Wrapper = styled.nav`
    margin: 0 auto;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: height 0.3s;

    ${Container}.scrolled & {
        height: 60px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        height: 60px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        padding: 0 24px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        padding: 0;
    }
`;

export const LogoWrapper = styled(Link)`
    display: inline-flex;
    align-items: center;
    width: 76px;
    transition: width 0.3s, height 0.3s;
    cursor: pointer;
`;

export const Logo = styled.img`
    width: 100%;
    height: 100%;
`;

export const List = styled.ul`
    display: flex;
    align-items: center;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        position: fixed;
        right: 0;
        top: 60px;
        flex-direction: column;
        transform: translateX(100%);
        padding-bottom: 32px;

        &.open {
            width: 100%;
            height: 100%;
            background-color: ${({theme}) => theme.colors.white};
            transform: translateX(0);
            z-index: 1000;
            overflow: auto;
        }
    }
`;

export const ListItem = styled.li`
    &:not(:last-child) {
        margin-right: 64px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        &:not(:last-child) {
            margin-right: 48px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop_small}) {
        &:not(:last-child) {
            margin-right: 42px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_big}) {
        &:not(:last-child) {
            margin-right: 36px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        &:not(:last-child) {
            margin-right: 24px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        margin-top: 32px;

        &:not(:last-child) {
            margin-right: 0;
        }
    }
`;

export const StyledLink = styled(Link)`
    font-size: 16px;
    font-weight: ${({theme}) => theme.weights.bold};
    color: ${({theme}) => theme.colors.black};
    font-family: "Rajdhani", sans-serif;
    transition: color 0.2s;
    cursor: pointer;

    &:hover {
        color: ${({theme}) => theme.colors.black_hover};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 14px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop_small}) {
        font-size: 12px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        font-size: 26px;
    }
`;

export const Burger = styled.button`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: none;
    margin-left: 48px;
    outline: none;
    background-color: transparent;
    display: none;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        display: flex;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        margin-left: 24px;
    }
`;

export const Line = styled.span`
    width: 22px;
    height: 3px;
    border-radius: 10px;
    background-color: ${({theme}) => theme.colors.black};
    transition: opacity 0.3s, transform 0.3s;

    &:nth-child(2) {
        margin: 4px 0;
    }

    ${Burger}.open & {

        &:first-child {
            transform: rotate(-45deg) translate(-4px, 2px);
        }

        &:nth-child(2) {
            opacity: 0;
            transform: translateX(100%);
        }

        &:nth-child(3) {
            transform: rotate(45deg) translate(-8px, -6px);
        }
    }
`;
